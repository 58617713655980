body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Apple Color Emoji", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Apple Color Emoji", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*::-webkit-scrollbar {
  -webkit-appearance: none;
}

*::-webkit-scrollbar:vertical {
  width: 11px;
}

*::-webkit-scrollbar:horizontal {
  height: 11px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.5);
}

input[type="time"]::-webkit-datetime-edit-ampm-field {
  display: none;
}

@font-face {
  font-family: "Century Gothic";
  src: url("./ressources/fonts/centuryGothic/CenturyGothic.ttf");
}
@font-face {
  font-family: "Century Gothic";
  src: url("./ressources/fonts/centuryGothic/GOTHICB.TTF");
  font-weight: bold;
}
@font-face {
  font-family: "Century Gothic";
  src: url("./ressources/fonts/centuryGothic/GOTHICI.TTF");
  font-style: italic;
}
@font-face {
  font-family: "Century Gothic";
  src: url("./ressources/fonts/centuryGothic/GOTHICBI.TTF");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Century Gothic";
  src: url("./ressources/fonts/centuryGothic/GOTHICB0.TTF");
  font-weight: bold;
  font-style: oblique;
}
